import React from "react";
import styled from "styled-components";
import "./index.css";
import { Player } from "@lottiefiles/react-lottie-player";
import * as logoWeTechFood from "../assets/lottie/logoWeTechFood.json";
import * as logoWeTechFoodSmall from "../assets/lottie/logoWeTechFood_small.json";

const LottiePlayer = styled(Player).attrs({
  autoplay: true,
  loop: true,
})`
  max-width: 100vw;
  max-height: 100vw;
`;

const Wrapper = styled.div`
  background: #1b2033;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  overflow: hidden;
`;

const screensaver = () => {
  return (
    <Wrapper
      onDoubleClick={(e) => {
        if (e.currentTarget.requestFullscreen) {
          e.currentTarget.requestFullscreen();
        } else if (e.currentTarget.mozRequestFullScreen) {
          e.currentTarget.mozRequestFullScreen();
        } else if (e.currentTarget.webkitRequestFullscreen) {
          e.currentTarget.webkitRequestFullscreen();
        } else if (e.currentTarget.msRequestFullscreen) {
          e.currentTarget.msRequestFullscreen();
        }
      }}
    >
      <LottiePlayer
        style={{ width: "100vw", height: "100vh" }}
        src={[logoWeTechFood, logoWeTechFoodSmall][0]}
      />
    </Wrapper>
  );
};

export default screensaver;
